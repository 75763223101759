import { useState, useMemo, memo } from 'react';
import { IconButton, Popover } from '@mui/material';

import Twemoji from 'components/common/Twemoji';

import EmojiSelector from './EmojiSelector';

import { EmojiWrapper } from './style';

const EMOJI_LIST = ['🥶', '🤣', '😁', '😱', '😬', '🤯', '🥵', '🤡', '💀', '😲', '👀'];

const EmojiButton = () => {
  const [currentEmoji, setCurrentEmoji] = useState(0);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);

  const EmojiSelectorMemo = useMemo(() => <EmojiSelector closeEmojiSelector={() => setAnchorEl(null)} />, []);

  return (
    <EmojiWrapper>
      <IconButton
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        onMouseEnter={() =>
          setCurrentEmoji((currentEmojiValue) => {
            if (currentEmojiValue === EMOJI_LIST.length - 1) {
              return 0;
            }
            return currentEmojiValue + 1;
          })
        }
      >
        <Twemoji emoji={EMOJI_LIST[currentEmoji]} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ visibility: anchorEl == null ? 'hidden' : 'visible' }}
        open
      >
        {EmojiSelectorMemo}
      </Popover>
    </EmojiWrapper>
  );
};

export default memo(EmojiButton);
