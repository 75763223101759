import { useState } from 'react';
import VideoGrid, { IMediaProps } from '../VideoGrid/VideoGrid';

const YouTube = () => {
  const [data, setData] = useState<IMediaProps[]>();

  return (
    <>
      <VideoGrid data={data} />
    </>
  );
};

export default YouTube;
