import { useMemo } from 'react';
import {
  Skeleton,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Avatar,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import { THEME } from 'utils/constants';

export interface IMediaProps {
  avatar: string;
  url: string;
  title: string;
  timestamp: number;
  imageURL: string;
  description: string;
}

const MediaCard = (props?: IMediaProps) => (
  <Card sx={{ maxWidth: 345, m: 2, backgroundColor: THEME.TERTIARY }}>
    <CardHeader
      avatar={
        props?.avatar ? (
          <Avatar
            alt="Ted talk"
            src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
          />
        ) : (
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        )
      }
      action={
        props?.url ? (
          <IconButton aria-label="settings">
            <MoreVertOutlined />
          </IconButton>
        ) : null
      }
      title={props?.title ?? <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
      subheader={props?.timestamp ?? <Skeleton animation="wave" height={10} width="40%" />}
    />
    {props?.imageURL ? (
      <CardMedia
        component="img"
        height="140"
        image="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"
        alt="Nicola Sturgeon on a TED talk stage"
      />
    ) : (
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
    )}
    <CardContent>
      {props?.description ? (
        <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
          {props.description}
        </Typography>
      ) : (
        <>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </>
      )}
    </CardContent>
  </Card>
);

interface IVideoGridProps {
  data?: IMediaProps[];
}

export const VideoGrid = (props: IVideoGridProps) => {
  const nullArrayMemo = useMemo(() => new Array(20).fill(null), []);

  return (
    <Stack m={4} overflow={'auto'} alignItems={'center'} spacing={4}>
      <Grid container>
        {(props.data ?? nullArrayMemo).map((media: IMediaProps | null) => (
          <Grid item xs={6} lg={4} xl={3}>
            <MediaCard {...media} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Stack>
  );
};

export default VideoGrid;
