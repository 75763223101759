import { useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { red } from '@mui/material/colors';

import { GlobalContext } from 'App';
import { RoomContext } from 'views/Room/Room';
import { THEME, VALID_USERNAME_REGEX } from 'utils/constants';
import { PrimaryButton, SecondaryButton, StyledTextField } from 'style/common';

import { GuestLoginContainer, GuestLoginWrapper, TermsContainer } from './style';

const GuestLogin = () => {
  const { setUserProfileData } = useContext(GlobalContext);
  const { socketRef } = useContext(RoomContext);
  const history = useHistory();
  const [username, setUsername] = useState('');

  return (
    <GuestLoginContainer>
      <GuestLoginWrapper>
        <Stack display={'flex'} alignContent={'center'} margin={3} spacing={2}>
          <Typography variant="button" fontWeight="bold">
            {username !== '' && !VALID_USERNAME_REGEX.test(username) ? (
              <span style={{ color: red[400] }}>
                Chat as - <em style={{ fontSize: '10px' }}>Your username contains invalid characters</em>
              </span>
            ) : (
              <>Chat as</>
            )}
          </Typography>
          <StyledTextField
            variant="outlined"
            placeholder="Enter nickname"
            onChange={(event) => setUsername(event.target.value)}
            error={username !== '' && !VALID_USERNAME_REGEX.test(username)}
            inputProps={{ maxLength: 20 }}
            autoComplete="off"
          />
          <PrimaryButton
            onClick={() => {
              // props.toggleMute(false); ?? why is this here
              setUserProfileData({
                is_guest: true,
                username: username,
                accent_color: '',
              });
              socketRef.current.emit('updateUsername', username);
            }}
          >
            Continue
          </PrimaryButton>
          <SecondaryButton onClick={() => history.push('/')} style={{ marginLeft: 0 }}>
            Cancel
          </SecondaryButton>
        </Stack>
      </GuestLoginWrapper>
      <TermsContainer>
        <Typography align="center">
          By continuing to use our services, you agree to our{' '}
          <Link to="/terms" target="_blank" style={{ padding: 0, color: THEME.ACCENT }}>
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link to="/privacy" target="_blank" style={{ padding: 0, color: THEME.ACCENT }}>
            Privacy Policy
          </Link>
          .
        </Typography>
      </TermsContainer>
    </GuestLoginContainer>
  );
};

export default GuestLogin;
