import { useEffect, useState, useContext, useRef } from 'react';
import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { ExpandMore, Search } from '@mui/icons-material';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

import ISupportProps from './types';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Support = (props: ISupportProps) => {
  const { invokeAuthenticatedCallback } = useContext(GlobalContext);
  const [searchFieldText, setSearchFieldText] = useState('');
  const [selectedFAQTab, setSelectedFAQTab] = useState(0);
  const faqRef = useRef(null);

  useEffect(() => {
    document.title = 'Synther | Support';
    if (props?.scrollToFaqAnchor) {
      faqRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <Header />
      <Stack
        pt={20}
        pb={10}
        position="relative"
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
        bgcolor={THEME.TERTIARY}
      >
        <Typography variant="h2" fontWeight={'bold'} pb={2}>
          Help Center
        </Typography>
        <TextField
          placeholder={'Search'}
          variant="outlined"
          style={{ margin: '0 10rem' }}
          onChange={(e) => setSearchFieldText(e.target.value)}
          sx={{
            width: '50%',
            minWidth: '20rem',
            input: {
              pl: 2,
              color: THEME.TEXT,
              background: THEME.SECONDARY, // "#272C38",
              borderRadius: '0.5rem',
            },
            label: {
              color: THEME.TEXT,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                variant="standard"
                children={<Search sx={{ color: THEME.IMPORTANT_TEXT }} />}
              />
            ),
          }}
        />
        <Button onClick={() => invokeAuthenticatedCallback()}>Create a support ticket</Button>
      </Stack>
      <Stack
        ref={faqRef}
        p={10}
        position="relative"
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
        bgcolor={THEME.SECONDARY}
      >
        <Typography variant="h3" fontWeight={'bold'} textAlign="center">
          Frequently Asked Questions
        </Typography>
        <div>
          <Box>
            <Tabs
              value={selectedFAQTab}
              onChange={(_, newValue) => setSelectedFAQTab(newValue)}
              aria-label="faq tabs"
              centered
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Ultra" {...a11yProps(1)} />
              {/* <Tab label="Other" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={selectedFAQTab} index={0}>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question1-content"
                id="question1-header"
              >
                <Typography variant="h6">What is a Room?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  A room is an invite-only virtual space within Synther where users can join to chat, share ideas, or
                  participate in shared streaming. Think of it like an online group where you can hang out, talk and do
                  things together, all within the app. Copy & Paste a YouTube link either in the chat or contextual
                  browser menu and have the stream be automatically synchronized within the room.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question2-content"
                id="question2-header"
              >
                <Typography variant="h6">How does video synchronization work?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  Depending on your configured settings for the room, the host/moderator is the only one that is able to
                  control the state of the video player; where if they pause it pauses for everyone, or if they skip
                  then it skips for everyone else in the room, etc.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question3-content"
                id="question3-header"
              >
                <Typography variant="h6">
                  Is it possible to let everyone in the room be able to control the state of the video player?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  Yes! Although you must configure your room settings to allow for that. Please keep in mind that shared
                  synchronization may lead to inconsistencies or lag to the synchronization process. If two users
                  perform an action at once then the server decides that the last action it recieved will be the final
                  one.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </CustomTabPanel>
          <CustomTabPanel value={selectedFAQTab} index={1}>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question1-content"
                id="question1-header"
              >
                <Typography variant="h6">What's Ultra?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  Ultra is a subscription service that unlocks features and perks across Synther, giving you more ways
                  to enjoy our services which also helps support us.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question2-content"
                id="question2-header"
              >
                <Typography variant="h6">How does Ultra work?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  When you subscribe to Ultra, you get access to features that were previously locked for you behind a
                  paywall - like using custom emojis, uploading bigger files, and more. When the subscription ends,
                  you'll lose access to these perks.
                </Typography>
              </AccordionDetails>
              <AccordionActions>
                <Button>Learn more</Button>
              </AccordionActions>
            </Accordion>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: THEME.IMPORTANT_TEXT }} />}
                aria-controls="question3-content"
                id="question3-header"
              >
                <Typography variant="h6">How much does Ultra cost?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  Ultra cost varies d epending on your region/country as well as plan. You can check Ultra prices under
                  User Settings &gt; Ultra.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </CustomTabPanel>
          <CustomTabPanel value={selectedFAQTab} index={2}></CustomTabPanel>
        </div>
      </Stack>
      <Footer />
    </>
  );
};

export default Support;
